import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container>
        <span className="text-muted">Lukas Ružauskas, 2020 - {new Date().getFullYear()}</span>
      </Container>
    </footer>
  );
};

export default Footer;
