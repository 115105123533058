import React from 'react';
import './base.css';
import Container from './container';
import Navigation from './navigation/navigation';
import Row from 'react-bootstrap/Row';
import Footer from './footer/footer';

class Template extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <>
        <Container>
          <Row>
            <Navigation location={this.props.location} />
          </Row>
          {children}
        </Container>
        <Footer />
      </>
    );
  }
}

export default Template;
