import React from 'react';
import { Helmet } from 'react-helmet';

export default ({ children }) => (
  <div className="container">
    <Helmet>
      <link rel="stylesheet" href="https://bootswatch.com/4/lux/bootstrap.min.css"></link>
    </Helmet>
    {children}
  </div>
);
