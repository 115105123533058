import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useStaticQuery, graphql, Link } from "gatsby";
import "./navigation.css";

const Navigation = ({ location }) => {
  const navigationItems = useStaticQuery(graphql`
    query NavigationQuery {
      allContentfulMenuList {
        edges {
          node {
            menu {
              id
              slug
              title
            }
          }
        }
      }
    }
  `).allContentfulMenuList.edges[0].node.menu;

  return (
    <Navbar expand="lg">
      <Link to="/">
        <Navbar.Brand>Lukas Ružauskas</Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav activeKey={location.pathname} className="mr-auto">
          {navigationItems.map((node) => {
            return (
              <Link key={node.id} to={`/${node.slug ? node.slug + "/" : ""}`}>
                {node.title}
              </Link>
            );
          })}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
